import React, { FunctionComponent } from 'react';
import NavBar from 'components/navbar';
import Title from 'components/title';
import LoginForm from 'views/login/login-form';
import RegistrationForm from './registration-form';

type Props = {
  institutionId: string;
};

const Invitation: FunctionComponent<Props> = ({
  institutionId: invitationId,
}) => {
  return (
    <>
      <NavBar />
      <div className="flex flex-wrap container justify-evenly bg-gray-200 mt-4 py-4">
        <div>
          <Title>Zarejestruj się</Title>
          <RegistrationForm institutionId={invitationId} />
        </div>
        <div>
          <Title>Zaloguj się</Title>
          <LoginForm />
        </div>
      </div>
    </>
  );
};

export default Invitation;

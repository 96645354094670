import React, { useState, useCallback } from 'react';
import { Form, Field, FieldRenderProps } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { required, composeValidators, minLength } from 'utils/validators';
import FieldError from 'components/forms/field-error';
import { Link, useNavigate } from '@reach/router';
import useAffiliateCode from 'utils/useAffiliateCode';
import firebase from 'utils/firebase';
import NavBar from 'components/navbar';
import Title from 'components/title';
import Button from 'components/button';
import { useId } from 'react-id-generator';
import { formatNumber } from '@ionaru/format-number';
import { apiProviderUrl } from 'utils/payments';
import { isDevelopment, isStaging } from 'utils/environment';
import FeatureFlag from 'featureFlags';

function formatPrice(price: number) {
  return formatNumber(price, 2, ',', ' ');
}

function useDiscountCode() {
  const initialPrice = 9.9;
  const [discountCodeInputId] = useId();
  const [price, setPrice] = useState(initialPrice);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const handleSubmit = useCallback(async (discountCode: string) => {
    setIsLoading(true);
    setIsError(false);
    try {
      const response = await fetch('/api/checkDiscountCode', {
        method: 'POST',
        body: JSON.stringify({ discountCode }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const responseData: { newPrice: number } = await response.json();
      setPrice(responseData.newPrice);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);
  return {
    discountCodeInputId,
    price,
    handleSubmit,
    isLoading,
    isError,
    isPriceModified: initialPrice !== price,
  };
}

const DiscountCodeInput = (
  props: FieldRenderProps<string> & ReturnType<typeof useDiscountCode>
) => {
  const {
    discountCodeInputId,
    price,
    isPriceModified,
    isLoading,
    handleSubmit,
    isError,
  } = props;
  return (
    <>
      <div className="flex border-2 border-solid border-gray-800 rounded justify-between overflow-hidden">
        <input
          id={discountCodeInputId}
          className="block flex-1 w-0"
          {...props.input}
        />
        <Button
          type="button"
          variant="input"
          onClick={() => handleSubmit(props.input.value)}
          isLoading={isLoading}
          disabled={isLoading}
        >
          <span className="mx-2 whitespace-no-wrap">Zaaplikuj kod</span>
        </Button>
      </div>
      {isPriceModified && (
        <span className="text-xs text-green-800">
          Cena została obniżona do {formatPrice(price)}
        </span>
      )}
      {isError && (
        <span className="text-xs text-red-800">Ten kod nie zadziała</span>
      )}
    </>
  );
};

const Purchase: React.FunctionComponent = () => {
  const affiliateCode = useAffiliateCode();
  const navigate = useNavigate();
  const discountCode = useDiscountCode();
  return (
    <>
      <NavBar withLogin />
      <Title>
        3 miesięczny dostęp do filmu &bdquo;Wycieczka do niewidzialnego
        świata&rdquo;
      </Title>
      <Form
        onSubmit={async ({
          email,
          password,
          discountCode: discountCodeValue,
        }) => {
          try {
            if (FeatureFlag.IMPROVED_PAYMENTS_PROCESS) {
              firebase.functions().httpsCallable('registerUser')({
                email,
                password,
              });
            }
            const { data: token } = await firebase
              .functions()
              .httpsCallable('registerTransaction')({
              email,
              password,
              affiliateCode,
              discountCode: discountCodeValue,
            });
            await firebase.auth().signInWithEmailAndPassword(email, password);

            if (token === null) {
              throw new Error('token not valid');
            }
            if (isDevelopment || isStaging) {
              await navigate('/app/video');
            } else {
              await navigate(
                `${apiProviderUrl}/trnRequest/${token}?waitForResult=true`
              );
            }
          } catch (error) {
            return {
              [FORM_ERROR]: 'Błąd serwera',
            };
          }
        }}
      >
        {({ handleSubmit, submitError, submitting }) => (
          <form
            onSubmit={handleSubmit}
            className="p-4 text-sm flex-shrink-0 w-full flex flex-col max-w-sm mx-auto"
          >
            <div className="text-red-800">{submitError}</div>
            <label className="flex flex-col mt-2">
              Adres email
              <Field
                name="email"
                type="email"
                validate={required()}
                required
                component="input"
                className="border-2 border-solid border-gray-800 rounded"
              />
            </label>
            <FieldError name="email" />
            <label className="flex flex-col mt-2">
              Nadaj hasło
              <Field
                name="password"
                type="password"
                validate={composeValidators(
                  required(),
                  minLength('Minimalna długość hasła to 6 znaków', 6)
                )}
                required
                component="input"
                className="border-2 border-solid border-gray-800 rounded"
              />
            </label>
            <FieldError name="password" />
            <label className="flex flex-col mt-2">
              Powtórz hasło
              <Field
                name="repeatedPassword"
                type="password"
                component="input"
                required
                validate={(repeatedPassword, values) => {
                  if (
                    (values as { password: string }).password !==
                    repeatedPassword
                  ) {
                    return 'Hasła w obu polach muszą być takie same';
                  }
                }}
                className="border-2 border-solid border-gray-800 rounded"
              />
            </label>
            <FieldError name="discountCode" />
            <label
              className="flex flex-col mt-2"
              htmlFor={discountCode.discountCodeInputId}
            >
              Kod rabatowy
            </label>
            <Field
              name="discountCode"
              component={DiscountCodeInput}
              {...discountCode}
              required
            />
            <FieldError name="discountCode" />
            <label className="flex items-baseline mt-2">
              <Field
                name="rules"
                type="checkbox"
                component="input"
                className="mr-1"
                required
                validate={required()}
              />
              <span>
                Akceptuję{' '}
                <Link to="/app/rules" className="underline">
                  regulamin
                </Link>
              </span>
            </label>
            <FieldError name="rules" />
            <p className="mt-4 text-center">
              Cena: {formatPrice(discountCode.price)} zł brutto za 3 miesiące
              dostępu do filmu
            </p>
            <Button
              type="submit"
              variant="success"
              className="mt-5"
              disabled={submitting}
              isLoading={submitting}
            >
              Kup teraz
            </Button>
            <p className="text-center">łatwa płatność kartą lub blikiem</p>
          </form>
        )}
      </Form>
    </>
  );
};

export default Purchase;

import React, { useEffect, useState } from 'react';
import firebase from 'utils/firebase';

type Props = {
  planPath: string | undefined;
  className?: string;
};

const PlanNameCell: React.FunctionComponent<Props> = ({
  planPath,
  className,
}) => {
  const [planName, setPlanName] = useState();

  useEffect(() => {
    async function run() {
      if (planPath) {
        const planQuery = await firebase.firestore().doc(planPath).get();
        setPlanName(planQuery.data?.()?.name);
      }
    }
    run();
  }, [planPath]);

  return <td className={className}>{planName}</td>;
};

export default PlanNameCell;

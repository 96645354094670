import React, { useEffect, useState } from 'react';
import VideoPlayer from 'components/video-player';
import firebase from 'utils/firebase';
import Title from 'components/title';
import NavBar from 'components/navbar';
import boardgamePrimary from 'assets/Gra_Planszowa_kolor.pdf';
import boardgameSecondary from 'assets/Gra_Planszowa_do_kolorowania.pdf';
import Button from 'components/button/button';
import FeatureFlag from 'featureFlags';
import { FORM_ERROR } from 'final-form';
import useAffiliateCode from 'utils/useAffiliateCode';
import { useNavigate } from '@reach/router';
import { Form } from 'react-final-form';
import { apiProviderUrl } from 'utils/payments';

const Video = () => {
  const [status, setStatus] = useState<'ok' | 'loading' | 'error'>('loading');
  const [videoId, setVideoId] = useState('');

  useEffect(() => {
    async function effect() {
      setStatus('loading');
      try {
        const { data: newVideoId } = await firebase
          .functions()
          .httpsCallable('getVideoCode')();
        setStatus('ok');
        setVideoId(newVideoId);
      } catch {
        setStatus('error');
      }
    }
    effect();
  }, []);

  const navigate = useNavigate();
  const affiliateCode = useAffiliateCode();

  if (status === 'loading') return <>Ładuję...</>;
  if (status === 'error' && FeatureFlag.IMPROVED_PAYMENTS_PROCESS)
    return (
      <>
        <NavBar withLogout />
        <div className="my-20 text-center flex flex-col items-center">
          <p className="mb-6">
            Wygląda na to, że nie opłaciłeś jeszcze dostępu do filmu...
          </p>
          <Form
            onSubmit={async ({ discountCode: discountCodeValue }) => {
              try {
                const {
                  data: token,
                } = await firebase
                  .functions()
                  .httpsCallable('registerTransaction')({
                  affiliateCode,
                  discountCode: discountCodeValue,
                });
                if (token === null) {
                  throw new Error('token not valid');
                }
                navigate(
                  `${apiProviderUrl}/trnRequest/${token}?waitForResult=true`
                );
              } catch (error) {
                return {
                  [FORM_ERROR]: 'Błąd serwera',
                };
              }
            }}
          >
            {({ handleSubmit, submitError, submitting }) => (
              <form
                onSubmit={handleSubmit}
                className="p-4 text-sm flex-shrink-0 w-full flex flex-col max-w-sm mx-auto"
              >
                <div className="text-red-800">{submitError}</div>
                <Button
                  type="submit"
                  variant="success"
                  disabled={submitting}
                  isLoading={submitting}
                >
                  Wykup dostęp do filmu
                </Button>
              </form>
            )}
          </Form>
        </div>
      </>
    );
  if (status === 'error') return <>Nie masz dostępu do filmu</>;

  return (
    <>
      <NavBar withLogout />
      <Title>&bdquo;Wycieczka do niewidzialnego świata&rdquo;</Title>
      <VideoPlayer videoId={videoId} isWide />
      <div className="flex items-center flex-col mt-6 mb-12 text-center text-sm">
        <Button
          as="a"
          href={boardgamePrimary}
          download="gra_planszowa_kolorowa"
          variant="info"
          className="mt-2 rounded"
        >
          Pobierz grę planszową (wersja kolorowa)
        </Button>
        <Button
          as="a"
          href={boardgameSecondary}
          download="gra_planszowa_do_pokolorowania"
          variant="info"
          className="mt-2 rounded"
        >
          Pobierz grę planszową (wersja do pokolorowania)
        </Button>
        <p>
          Zagraj w grę planszową i powtórz zdobytą wiedzę podczas zabawy z
          dzieckiem
        </p>
      </div>
    </>
  );
};

export default Video;

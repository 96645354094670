import React, { FunctionComponent } from 'react';
import { Form, Field } from 'react-final-form';
import { required, composeValidators, minLength } from 'utils/validators';
import firebase from 'utils/firebase';
import { FORM_ERROR } from 'final-form';
import FieldError from 'components/forms/field-error';
import Button from 'components/button';

type Props = {
  institutionId: string;
};

const RegistrationForm: FunctionComponent<Props> = ({ institutionId }) => {
  return (
    <Form
      onSubmit={async ({ email, password }) => {
        try {
          await firebase.functions().httpsCallable('registerParentAccount')({
            email,
            password,
            institutionId,
          });
          await firebase.functions().httpsCallable('acceptInvitation')({
            email,
            institutionId,
          });
          await firebase.auth().signInWithEmailAndPassword(email, password);
        } catch (error) {
          if (error.code === 'auth/weak-password') {
            return {
              password: 'Wpisz bardziej skomplikowane hasło',
            };
          }
          if (error.code === 'already-exists') {
            try {
              await firebase.auth().signInWithEmailAndPassword(email, password);
            } catch {
              return {
                [FORM_ERROR]: 'Twoje konto już istnieje, zaloguj się',
              };
            }
          }
          return {
            [FORM_ERROR]: error.message,
          };
        }
      }}
    >
      {({ handleSubmit, submitError, submitting }) => (
        <form
          onSubmit={handleSubmit}
          className="flex flex-col justify-center items-end flex-shrink-0 px-4"
        >
          <div className="text-red-800">{submitError}</div>
          <label className="block pt-2">
            Twój adres email:
            <Field
              name="email"
              type="email"
              validate={required()}
              required
              component="input"
              className="ml-2 border-2 border-solid border-gray-800 rounded w-64 text-sm"
            />
          </label>
          <FieldError name="email" className="pr-20" />
          <label className="block pt-2">
            Nadaj hasło:
            <Field
              name="password"
              type="password"
              validate={composeValidators(
                required(),
                minLength('Minimalna długość hasła to 6 znaków', 6)
              )}
              required
              component="input"
              className="ml-2 border-2 border-solid border-gray-800 rounded w-64 text-sm"
            />
          </label>
          <FieldError name="password" className="pr-20" />
          <label className="block pt-2">
            Powtórz hasło:
            <Field
              name="repeatedPassword"
              type="password"
              component="input"
              required
              validate={(repeatedPassword, values) => {
                if (
                  (values as { password: string }).password !== repeatedPassword
                ) {
                  return 'Hasła w obu polach muszą być takie same';
                }
              }}
              className="ml-2 border-2 border-solid border-gray-800 rounded w-64 text-sm"
            />
          </label>
          <FieldError name="repeatedPassword" className="pr-20" />
          <Button
            type="submit"
            variant="success"
            className="self-center my-6 px-10"
            disabled={submitting}
            isLoading={submitting}
          >
            Załóż konto
          </Button>
        </form>
      )}
    </Form>
  );
};

export default RegistrationForm;

import React from 'react';
import NavBar from 'components/navbar';
import Button from 'components/button';
import { Link } from 'gatsby';

const PaymentSuccess: React.FunctionComponent = () => {
  return (
    <>
      <NavBar />
      <div className="my-10 max-w-xs text-center mx-auto">
        <div>Płatność udana</div>
        <Button
          as={Link}
          to="/app/video"
          variant="success"
          className="mt-1 block"
        >
          Oglądaj
        </Button>
      </div>
    </>
  );
};

export default PaymentSuccess;

import React, { FunctionComponent } from 'react';
import { useFormState } from 'react-final-form';
import cx from 'classnames';

type Props = {
  name: string;
  className?: string;
};

const FieldError: FunctionComponent<Props> = ({ className, name }) => {
  const state = useFormState();
  if (state.touched && state.touched[name]) {
    return (
      <div className={cx('text-red-800 text-xs', className)}>
        {state.errors[name]}
      </div>
    );
  }
  return null;
};

export default FieldError;

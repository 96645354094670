import React, { FunctionComponent } from 'react';
import NavBar from 'components/navbar';
import Title from 'components/title';
import LoginForm from './login-form';

const Login: FunctionComponent = () => {
  return (
    <>
      <NavBar withBuyButton />
      <Title>Zaloguj się</Title>
      <LoginForm />
    </>
  );
};

export default Login;

import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Button, { LogoutButton } from 'components/button';
import Img from 'gatsby-image';
import cx from 'classnames';

type Props = {
  withLogout?: boolean;
  withLogin?: boolean;
  withBuyButton?: boolean;
  withDashboardNaigation?: boolean;
  className?: string;
  isCurrent?: boolean;
};

const NavBar = ({
  withLogout,
  withLogin,
  withBuyButton,
  withDashboardNaigation,
  className,
}: Props) => (
  <StaticQuery
    query={graphql`
      {
        file(name: { eq: "wu-logo" }) {
          childImageSharp {
            fixed(width: 92, height: 80) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    `}
    render={(data) => (
      <nav
        className={cx(
          'font-body flex justify-between items-center pt-2',
          className
        )}
      >
        <div>
          <Link to="/">
            <Img fixed={data.file.childImageSharp.fixed} alt="logo" />
          </Link>
        </div>
        <div>
          {withDashboardNaigation && (
            <>
              <Button
                as={Link}
                to="/app/dashboard"
                variant="navbar-link"
                className="ml-2 inline-block"
              >
                Instytucje
              </Button>
              <Button
                as={Link}
                to="/app/dashboard/movies"
                variant="navbar-link"
                className="ml-2 inline-block"
              >
                Filmy
              </Button>
            </>
          )}
          {withLogout && (
            <LogoutButton variant="white" className="ml-2 inline-block" />
          )}
          {withLogin && (
            <Button
              as={Link}
              to="/app/login"
              variant="transparent"
              className="ml-2 inline-block"
            >
              Zaloguj
            </Button>
          )}
          {withBuyButton && (
            <Button
              as={Link}
              to="/app/purchase"
              variant="success"
              className="ml-2 inline-block"
            >
              Kup teraz
            </Button>
          )}
        </div>
      </nav>
    )}
  />
);

export default NavBar;

import React, { useState, useEffect } from 'react';
import firebase from 'utils/firebase';
import { useLocation } from '@reach/router';
import NavBar from 'components/navbar';
import NewInstitutionForm from './new-institution-form';
import PlanNameCell from './plan-name-cell';

const Dashboard = () => {
  const [subscriptions, setSubscriptios] = useState<
    { id: string; seats: number; institutionPath: string; plan: string }[]
  >([]);
  const [institutionNames, setInstitutionNames] = useState<string[]>([]);
  useEffect(() => {
    return firebase
      .firestore()
      .collection('subscriptions')
      .orderBy('createdTs', 'desc')
      .onSnapshot((querySnapshot) => {
        const newSubscriptions = querySnapshot.docs
          .map((subscription) => ({
            id: subscription.id,
            seats: subscription.data().seats,
            institutionPath: subscription.data().institution,
            plan: subscription.data().plan,
          }))
          .filter(({ institutionPath }) => !!institutionPath);
        setSubscriptios(newSubscriptions);
      });
  }, []);
  useEffect(() => {
    async function effect() {
      if (typeof subscriptions === 'undefined') return;
      const institutionsSnapshot = await Promise.all(
        subscriptions.map((subscription) =>
          firebase.firestore().doc(subscription.institutionPath).get()
        )
      );
      setInstitutionNames(
        institutionsSnapshot.map((institution) => institution.data()?.name)
      );
    }
    effect();
  }, [subscriptions]);

  const location = useLocation();

  async function handleSubmit({ name, allowance, planId }) {
    const institutionRef = await firebase
      .firestore()
      .collection('institutions')
      .add({ name, allowance });
    await firebase
      .firestore()
      .collection('subscriptions')
      .add({
        seats: allowance,
        transaction: { isConfirmed: true },
        institution: institutionRef.path,
        createdTs: firebase.firestore.Timestamp.now(),
        plan: `plans/${planId}`,
      });
  }

  return (
    <div className="container px-3">
      <div className="pb-8 pt-2 text-sm">
        <NavBar className="mb-6" withDashboardNaigation withLogout />
        <NewInstitutionForm onSubmit={handleSubmit} />
      </div>
      <h1 className="text-xl mt-6 mb-4">Lista przedszkoli</h1>
      <table className="table-auto w-full mb-8 text-sm">
        <thead>
          <tr className="font-bold">
            <td className="border-1 px-3 py-2">Nazwa</td>
            <td className="border-1 px-3 py-2">Ilość miejsc</td>
            <td className="border-1 px-3 py-2">Nazwa filmu</td>
            <td className="border-1 px-3 py-2">Link zapraszający</td>
          </tr>
        </thead>
        <tbody>
          {subscriptions.map(({ id, institutionPath, seats, plan }, i) => (
            <tr key={id}>
              <td className="border-1 px-3 py-2">{institutionNames[i]}</td>
              <td className="border-1 px-3 py-2">{seats}</td>
              <PlanNameCell planPath={plan} className="border-1 px-3 py-2" />
              <td className="border-1 px-3 py-2">
                {institutionPath && (
                  <>
                    https://{location.host}/app/invitation/
                    {institutionPath.replace('institutions/', '')}
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Dashboard;

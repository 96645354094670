import React from 'react';
import cx from 'classnames';
import { RiLoader5Line } from 'react-icons/ri';
import { Link, GatsbyLinkProps } from 'gatsby';

type HTMLButtonElementProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

type HTMLAnchorElementProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

type Variant =
  | 'success'
  | 'white'
  | 'transparent'
  | 'info'
  | 'default'
  | 'input'
  | 'navbar-link';

type CommonProps = {
  variant?: Variant;
  isLoading?: boolean;
};

type ButtonProps = HTMLButtonElementProps &
  Required<Pick<HTMLButtonElementProps, 'type'>> & {
    as?: 'button';
  };

type AnchorProps = HTMLAnchorElementProps & {
  as: 'a';
};

type LinkProps = GatsbyLinkProps<object> & {
  as: typeof Link;
};

function Button(props: CommonProps & LinkProps): React.ReactElement;
function Button(props: CommonProps & AnchorProps): React.ReactElement;
function Button(props: CommonProps & ButtonProps): React.ReactElement;

function Button(props: CommonProps & (ButtonProps | AnchorProps | LinkProps)) {
  const { variant = 'default', isLoading = false } = props;
  const className = cx(
    'shadow-lg focus:outline-none focus:shadow-outline relative text-center ',
    {
      'text-black shadow-none px-3 py-1 rounded-lg bg-transparent text-black text-sm focus:bg-gray-200 hover:bg-gray-200 active:bg-gray-400':
        variant === 'navbar-link',
      'bg-green-700 px-3 py-1 rounded-lg text-white text-sm focus:bg-green-600 hover:bg-green-600 active:bg-green-800':
        variant === 'success',
      'bg-blue-700 px-3 py-1 rounded-lg text-sm text-white focus:bg-blue-600 hover:bg-blue-600 active:bg-blue-800':
        variant === 'info',
      'shadow-none px-3 py-1 rounded-lg bg-transparent text-black text-sm focus:bg-gray-200 hover:bg-gray-200 active:bg-gray-400':
        variant === 'transparent',
      'bg-gray-700 px-3 py-1 rounded-lg text-gray-200 text-sm focus:bg-gray-600 hover:bg-gray-600 active:bg-gray-800':
        variant === 'white',
      'bg-gray-800 text-xs text-white focus:bg-gray-700 hover:bg-gray-700 active:bg-gray-900':
        variant === 'input',
      'bg-white text-sm px-3 py-1 rounded-lg': variant === 'default',
    },
    props.className
  );
  const children = (
    <>
      <span className={cx({ visible: !isLoading, invisible: isLoading })}>
        {props.children}
      </span>
      <span
        className={cx('absolute inset-0 flex items-center justify-center', {
          invisible: !isLoading,
          visible: isLoading,
        })}
      >
        <RiLoader5Line style={{ animation: 'spinner .75s linear infinite' }} />
      </span>
    </>
  );

  const isActive = ({ isCurrent }) => {
    if (variant === 'navbar-link') {
      return {
        className: cx(className, { 'text-green-700': isCurrent }),
      };
    }
    return {};
  };

  if (props.as === Link) {
    const { as, isLoading: isLoading_, ...otherProps } = props;
    return React.createElement(
      as,
      { ...otherProps, className, getProps: isActive },
      children
    );
  }
  if (props.as === 'a') {
    const { as, isLoading: isLoading_, ...otherProps } = props;
    return React.createElement(
      props.as,
      { ...otherProps, className },
      children
    );
  }
  if (props.as === 'button' || props.as === undefined) {
    const { as = 'button', isLoading: isLoading_, ...otherProps } = props;
    return React.createElement(as, { ...otherProps, className }, children);
  }
  return <></>;
}

export default Button;

// https://github.com/reach/router/issues/141#issuecomment-578526448
import { RouteComponentProps, useLocation } from '@reach/router';
import { Location } from 'history';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import firebase from 'utils/firebase';
import { getUserRole, Role } from 'utils/auth';

type Props<T extends Partial<Record<string, string>>> = RouteComponentProps<
  T
> & {
  component: (props: RouteComponentProps<T>) => React.ReactNode;
  allowedRoles?: Role[];
};

enum AuthState {
  UNKNOWN,
  AUTHENTICATED,
  UNAUTHENTICATED,
}

enum PermissionState {
  UNKNOWN,
  PERMISSION_DENIED,
  ALLOWED,
}

const PermissionDenied = () => {
  return <>Nie masz uprawnień aby zobaczyć tę stronę</>;
};

const ConditionalRender: React.FunctionComponent<{
  permissionState: PermissionState;
}> = ({ children, permissionState }) => {
  if (permissionState === PermissionState.PERMISSION_DENIED) {
    return <PermissionDenied />;
  }
  return <>{permissionState === PermissionState.ALLOWED && children}</>;
};

function Route<Params = {}>({
  component,
  allowedRoles,
  ...props
}: Props<Params>) {
  const [authState, setAuthState] = useState<AuthState>(AuthState.UNKNOWN);
  const [permissionState, setPermissionState] = useState<PermissionState>(
    PermissionState.UNKNOWN
  );
  const location = useLocation() as Window['location'] &
    Location<{ from?: string } | undefined>;

  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setAuthState(AuthState.AUTHENTICATED);
      } else {
        setAuthState(AuthState.UNAUTHENTICATED);
      }
    });
  }, []);

  useEffect(() => {
    async function checkPermissions() {
      if (authState === AuthState.UNAUTHENTICATED) {
        if (allowedRoles) {
          navigate('/app/login', {
            state: { from: location.pathname },
            replace: true,
          });
        } else {
          setPermissionState(PermissionState.ALLOWED);
        }
      }
      if (authState === AuthState.AUTHENTICATED) {
        const role = await getUserRole();
        if (allowedRoles) {
          if (allowedRoles.includes(role)) {
            setPermissionState(PermissionState.ALLOWED);
          } else {
            setPermissionState(PermissionState.PERMISSION_DENIED);
          }
        } else if (location.state?.from) {
          navigate(location.state?.from, { replace: true });
        } else {
          if (location.pathname === '/app/logout') return;
          if (role === Role.ADMIN || role === Role.MANAGER) {
            navigate('/app/dashboard', { replace: true });
          }
          navigate('/app/video', { replace: true });
        }
      }
    }
    checkPermissions();
  }, [allowedRoles, location.pathname, authState]);

  return (
    <ConditionalRender permissionState={permissionState}>
      {component(props as RouteComponentProps<Params>)}
    </ConditionalRender>
  );
}

export default Route;

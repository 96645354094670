import { useEffect } from 'react';
import firebase from 'utils/firebase';
import LogRocket from 'logrocket';
import { isProduction } from 'utils/environment';

if (isProduction) {
  LogRocket.init('rountb/wesoy-uniwersytet');
}

export default function useLogRocket() {
  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        LogRocket.identify(user.uid, {
          ...(user.displayName !== null && { name: user.displayName }),
          ...(user.email !== null && { email: user.email }),
        });
      }
    });
  }, []);
}

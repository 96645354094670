import React from 'react';
import VideoPlayer from 'components/video-player';
import { Link } from 'gatsby';
import useAffiliateCode from 'utils/useAffiliateCode';
import NavBar from 'components/navbar';
import Title from 'components/title';
import Button from 'components/button';

const Trailer = ({ withPurchaseOption }) => {
  useAffiliateCode();
  return (
    <>
      <NavBar withLogin withBuyButton />
      <div className="text-center text-sm flex flex-col items-center">
        <Title>Kiedy ostatnio się śmiałeś? Pośmiej się razem z Dzieckiem</Title>
        <p className="text-base line-length">
          W konsultacji z lekarzem, specjalistą medycyny rodzinnej
          przygotowaliśmy dla Dzieci lekcję w formie kabaretu.
        </p>
        <p className="mt-8">Zobacz zwiastun:</p>
        <VideoPlayer videoId="iWg49d9yEogCGaYLLU7sfU" />
        <p className="text-left line-length">
          Proponujemy Państwu przekazanie Dziecku czegoś naprawdę wartościowego,
          aby oderwało się od ciągłego oglądania bajek lub grania w gry
          komputerowe.
        </p>
        <p className="mt-6">
          Cena: 9,90 zł brutto <br /> za 3 miesiące dostępu do filmu
        </p>
        {withPurchaseOption && (
          <>
            <Button
              as={Link}
              to="app/purchase"
              variant="success"
              className="mt-5"
            >
              Kup teraz
            </Button>
            <p>łatwa płatność kartą lub blikiem</p>
          </>
        )}
      </div>
    </>
  );
};

export default Trailer;

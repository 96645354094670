import firebase from './firebase';

export enum Role {
  ADMIN = 'admin',
  MANAGER = 'manager',
  PARENT = 'parent',
}

async function getUserClaims() {
  const idToken = await firebase.auth().currentUser?.getIdTokenResult();
  return idToken?.claims;
}

export async function getUserRole() {
  const claims = await getUserClaims();
  switch (claims?.role) {
    case Role.ADMIN:
      return Role.ADMIN;
    case Role.MANAGER:
      return Role.MANAGER;
    default:
      return Role.PARENT;
  }
}

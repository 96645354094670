import React from 'react';
import PropTypes from 'prop-types';
import Footer from './footer';

import './layout.css';

const Layout = ({ children }) => (
  <div className="flex flex-col min-h-screen">
    <main className="font-body text-base mx-12 flex-auto">{children}</main>
    <Footer />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

import { useEffect, useMemo } from 'react';
import useSearchParams, { asString } from './useSearchParams';

export default function useAffiliateCode() {
  const [params] = useSearchParams();
  const affiliateCode = useMemo(() => asString(params.affiliateCode), [
    params.affiliateCode,
  ]);
  useEffect(() => {
    if (affiliateCode) {
      localStorage.setItem('affiliateCode', affiliateCode);
    }
  }, [affiliateCode]);
  return localStorage.getItem('affiliateCode');
}

import { firestore } from 'firebase';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';
import { useCollection } from 'react-firebase-hooks/firestore';
import { isDevelopment, isStaging } from './environment';

const productionConfig = {
  apiKey: 'AIzaSyDstps9nrhb3NpIzOLzRwfiJ4xJujZjbnY',
  authDomain: 'film-3ab63.firebaseapp.com',
  databaseURL: 'https://film-3ab63.firebaseio.com',
  projectId: 'film-3ab63',
  storageBucket: 'film-3ab63.appspot.com',
  messagingSenderId: '279837606320',
  appId: '1:279837606320:web:a9a153880a426f73425675',
};

const stagingConfig = {
  apiKey: 'AIzaSyBaOJfA-JhvCTbXHpoGNXnXibcCZiVEFzE',
  authDomain: 'wesoly-uniwersytet-film-test.firebaseapp.com',
  databaseURL: 'https://wesoly-uniwersytet-film-test.firebaseio.com',
  projectId: 'wesoly-uniwersytet-film-test',
  storageBucket: 'wesoly-uniwersytet-film-test.appspot.com',
  messagingSenderId: '167915660415',
  appId: '1:167915660415:web:406e916166bd7156068b4a',
};

if (!firebase.apps.length) {
  if (isDevelopment || isStaging) {
    firebase.initializeApp(stagingConfig);
  } else {
    firebase.initializeApp(productionConfig);
  }
}

function getDocumentData<T>(
  queryDocumentSnapshot: firestore.QueryDocumentSnapshot<T>
) {
  return {
    id: queryDocumentSnapshot.id,
    ...queryDocumentSnapshot.data(),
  };
}

function getCollectionDocuments<T>(querySnapshot: firestore.QuerySnapshot<T>) {
  if (querySnapshot) {
    return querySnapshot.docs.map((queryDocumentSnapshot) =>
      getDocumentData<T>(queryDocumentSnapshot)
    );
  }
  return undefined;
}

export function useCollectionDocuments<T>(
  ...args: Parameters<typeof useCollection>
): [({ id: string } & T)[] | undefined, boolean, Error | undefined] {
  const [snapshot, isLoading, error] = useCollection(...args);
  const documents = getCollectionDocuments<T>(
    snapshot as firestore.QuerySnapshot<T>
  );
  return [documents, isLoading, error];
}

if (process.env.NODE_ENV === 'development') {
  // Uncomment if you want to use local functions and firestore
  // firebase.functions().useFunctionsEmulator('http://localhost:5001');
  // firebase.firestore().settings({ host: 'localhost:8080', ssl: false });

  if (typeof window !== 'undefined') {
    window.firebase = firebase;
  }
}

export default firebase;

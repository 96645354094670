import React, { useEffect } from 'react';
import firebase from 'utils/firebase';
import { navigate } from '@reach/router';

type Props = {};

const Logout: React.FunctionComponent<Props> = () => {
  useEffect(() => {
    firebase.auth().signOut();
    navigate('/app/login', { replace: true });
  }, []);
  return null;
};

export default Logout;

export const isStaging = (() => {
  if (typeof window !== 'undefined') {
    return window.location.host === 'wesoly-uniwersytet-film-test.web.app';
  }
  return true;
})();

export const isProduction = (() => {
  if (typeof window !== 'undefined') {
    return window.location.host === 'film.wesolyuniwersytet.pl';
  }
  return true;
})();

export const isDevelopment = process.env.NODE_ENV === 'development';

import React, { ComponentProps } from 'react';
import { navigate } from '@reach/router';
import Button from './button';

const LogoutButton = ({
  onClick,
  ...props
}: Omit<ComponentProps<typeof Button>, 'type'>) => (
  <Button
    {...props}
    variant="transparent"
    type="button"
    onClick={(event) => {
      navigate('/app/logout');
      if (onClick) onClick(event);
    }}
  >
    Wyloguj
  </Button>
);

export default LogoutButton;

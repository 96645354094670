import React, { FunctionComponent } from 'react';
import { Form, Field } from 'react-final-form';
import { required } from 'utils/validators';
import firebase from 'utils/firebase';
import { FORM_ERROR } from 'final-form';
import FieldError from 'components/forms/field-error';
import Button from 'components/button';
import { Location } from 'history';
import { useLocation } from '@reach/router';

const LoginForm: FunctionComponent = () => {
  const location = useLocation() as Window['location'] &
    Location<{ from?: string } | undefined>;

  return (
    <Form
      onSubmit={async ({ email, password }) => {
        try {
          await firebase.auth().signInWithEmailAndPassword(email, password);
        } catch (error) {
          if (error.code === 'auth/wrong-password') {
            return {
              password: 'Nieprawidłowe hasło',
            };
          }
          return {
            [FORM_ERROR]: error.message,
          };
        }
      }}
    >
      {({ handleSubmit, submitError, submitting }) => (
        <form
          onSubmit={handleSubmit}
          className="flex-shrink-0 flex flex-col justify-center items-center px-4"
        >
          {location.state?.from && (
            <div className="text-red-800">
              Żeby uzyskać dostęp do tej strony, musisz się zalogować.
            </div>
          )}
          <div className="text-red-800">{submitError}</div>
          <label className="block pt-2 ">
            Email:
            <Field
              type="email"
              component="input"
              name="email"
              validate={required()}
              required
              className="ml-2 border-2 border-solid border-gray-800 rounded w-64 text-sm"
            />
          </label>
          <FieldError name="email" className="pr-10" />
          <label className="block pt-2">
            Hasło:
            <Field
              type="password"
              component="input"
              name="password"
              validate={required()}
              required
              className="ml-2 border-2 border-solid border-gray-800 rounded w-64 text-sm"
            />
          </label>
          <FieldError name="password" className="pr-10" />
          <Button
            type="submit"
            variant="success"
            className="my-6 px-10"
            disabled={submitting}
            isLoading={submitting}
          >
            Zaloguj
          </Button>
        </form>
      )}
    </Form>
  );
};

export default LoginForm;

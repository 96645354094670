import React from 'react';

type Props = {};

const Rules: React.FunctionComponent<Props> = () => {
  return (
    <div className="m-5">
      <ol className="list-decimal ml-5">
        <li>
          Dane firmy: Anna Nowak Wesoły Uniwersytet Nip: 632 193 16 91 Regon:
          REGON: 243142571
        </li>
        <li>
          Sklep prowadzi sprzedaż filmów edukacyjnych, do których prawa posiada
          Wesoły Uniwersytet.
        </li>
        <li>
          Po kupieniu towaru w wersji elektronicznej, nie przysługuje prawo do
          odstąpienia od umowy.
        </li>
        <li>
          Reklamacje proszę kierować na adres e-mail:
          e-edukacja@wesolyuniwersytet.pl. Reklamacje będą rozpatrywane w ciągu
          14 dni roboczych od daty zgłoszenia.
        </li>
        <li>
          Administratorem danych osobowych zawartych w serwisie jest Wesoły
          Uniwersytet z siedzibą w Bronisławów 28a, 96-314 Baranów, oraz Serwis
          Przelewy24.
        </li>
        <li>
          Pytania proszę kierować na adres: e-edukacja@wesolyuniwersytet.pl lub
          telefonicznie pod nr tel.: 534 002 977.
        </li>
      </ol>
    </div>
  );
};

export default Rules;

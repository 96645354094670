import React, { FunctionComponent, useState, useEffect } from 'react';
import firebase from 'utils/firebase';
import { Form, Field } from 'react-final-form';
import FieldError from 'components/forms/field-error';
import Button from 'components/button';
import { required } from 'utils/validators';

type Props = {
  onSubmit: (values: {
    name: string;
    allowance: number;
    planId: string;
  }) => void;
};

const NewInstitutionForm: FunctionComponent<Props> = ({ onSubmit }) => {
  const [plans, setPlans] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    return firebase
      .firestore()
      .collection('plans')
      .orderBy('name')
      .onSnapshot((querySnapshot) => {
        const newPlans = querySnapshot.docs.map((plan) => ({
          id: plan.id,
          name: plan.data().name,
        }));
        setPlans(newPlans);
      });
  }, []);
  return (
    <Form
      // @ts-ignore
      onSubmit={async ({ name, allowance, planId }, { restart }) => {
        await onSubmit({ name, planId, allowance: Number(allowance) });
        setTimeout(() => restart());
      }}
    >
      {({ submitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <label className="block">
            Nazwa przedszkola:
            <Field
              name="name"
              type="text"
              component="input"
              validate={required()}
              required
              className="border-1 border-gray-300 border-solid m-2"
            />
          </label>
          <FieldError name="name" />
          <label className="block">
            Ilość przyznanych miejsc:
            <Field
              name="allowance"
              type="number"
              component="input"
              validate={required()}
              required
              className="border-1 border-gray-300 border-solid m-2"
            />
          </label>
          <FieldError name="allowance" />
          <label className="block">
            Film:
            <Field
              name="planId"
              component="select"
              validate={required()}
              required
              className="border-1 border-gray-300 border-solid m-2"
            >
              <option aria-label="Empty option" />
              {plans.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </Field>
          </label>
          <Button
            type="submit"
            variant="success"
            className="mt-1"
            disabled={submitting}
            isLoading={submitting}
          >
            Dodaj przedszkole
          </Button>
        </form>
      )}
    </Form>
  );
};

export default NewInstitutionForm;

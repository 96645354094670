import { useMemo, useCallback } from 'react';
import { parse, stringify, ParsedQuery } from 'query-string';
import { useLocation, useNavigate } from '@reach/router';

export function asString(param: ParsedQuery[string]) {
  if (Array.isArray(param)) {
    return param[0];
  }
  return param || '';
}

export default function useSearchParams(): [
  ParsedQuery,
  (newParams: { [key: string]: any }) => void
] {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useMemo(() => parse(location.search), [location.search]);

  const setParams = useCallback(
    (newParams: { [key: string]: any }) => {
      navigate(
        `${location.origin}/?${stringify({
          ...parse(location.search),
          ...newParams,
        })}`
      );
    },
    [location]
  );

  return [params, setParams];
}

import React, { FunctionComponent } from 'react';

type Props = {
  videoId: string;
  isWide?: boolean;
};

const VideoPlayer: FunctionComponent<Props> = ({ videoId, isWide }) => {
  const placeholderRef = React.useCallback(
    (placeholderNode) => {
      if (typeof window !== 'undefined' && placeholderNode) {
        // eslint-disable-next-line global-require
        const vidyardEmbed = require('@vidyard/embed-code');
        vidyardEmbed.api.renderPlayer(placeholderNode);
      }
    },
    [videoId]
  );

  return (
    <div
      className="max-w-full"
      style={{
        width: `calc((100vh - 90px) * ${isWide ? '16/9' : '64/45'})`,
        margin: '20px auto',
        padding: '0 20px',
      }}
    >
      <img
        style={{
          width: '100%',
          margin: 'auto',
          display: 'block',
        }}
        ref={placeholderRef}
        src={`https://play.vidyard.com/${videoId}.jpg`}
        data-uuid={videoId}
        data-v={4}
        data-type="inline"
        alt="movie"
      />
    </div>
  );
};

export default VideoPlayer;

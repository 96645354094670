import React from 'react';
import { Link } from 'gatsby';

const Footer = () => (
  <footer className="font-body flex justify-between py-4 px-12">
    <div>
      <p>WESOŁY UNIWERSYTET</p>
      <p>tel: 534 002 977</p>
      <p>NIP: 6321931691</p>
      <p>REGON: 243142571</p>
    </div>
    <Link to="/app/rules" className="underline text-blue-700">
      Regulamin
    </Link>
  </footer>
);

export default Footer;
